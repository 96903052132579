.wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
   
  .wrapper > * {
    padding: 20px;
  }
   
  .page-mainn {
    flex-grow: 1;
  }


  /* variables */
:root {
    --main-white-color: white;
    --main-purple-color: #9e89b8;
  }
   
  .page-mainn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 350px;
    
    color: var(--main-white-color);
  }
   
  .page-mainn div {
    max-width: 500px;
  }
   
  @media screen and (min-width: 768px) {
    .page-mainn {
      padding-left: 90px;
    }
  }


  .image{
    width: 300px ;
    height: 200px;
    
    
    left: 10px;
    float: left;
    margin-top: 50px;
    
    margin-left: 1050px;
  
    position: absolute;
    
}

.form{
    
    margin-bottom: 1000px;
    
}
